import {
  Box,
  Group,
  MultiSelect,
  NumberInput,
  RangeSlider,
  Skeleton,
  Switch,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconClipboardCheck } from '@tabler/icons-react';
import { useState } from 'react';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { useProjectPermissions } from '@/core/hooks/use-permissions/use-project-permissions';
import { splitFilters } from '@/core/utils/parse-query-param-arrays/parse-query-param-arrays';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import Badge from '@/fine-tune/components/badge/badge';
import Button from '@/fine-tune/components/button/button';
import { OD_ERROR_MAP } from '@/fine-tune/constants/object-detection.constants';
import { SS_ERROR_MAPPING } from '@/fine-tune/constants/semantic-segmentation.constants';
import {
  FormValues,
  useCreateSlice
} from '@/fine-tune/hooks/query-hooks/use-create-slice/use-create-slice';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import { truncateArray } from '@/fine-tune/hooks/use-active-filters-dictionary/use-active-filters-dictionary';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import {
  useComputedParameters,
  useMetaFilters,
  useParametersStore,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';
import { useErrorTypesMetaFilter } from '@/fine-tune/stores/parameters-store/use-store-meta-filters/use-store-meta-filters';
import { MetaFilter } from '@/fine-tune/types/query.types';

import MetaFiltersForm, { COMPARATORS } from './meta-filters-form';

type FieldLabels =
  | 'sliceName'
  | 'searchTerm'
  | 'misclassifiedOnly'
  | 'goldFilter'
  | 'predFilter'
  | 'classFilter'
  | 'isRegex'
  | 'spanSearch'
  | 'dep';

const LABEL_FILTERS = [
  { key: 'goldFilter', label: 'Ground Truth' },
  { key: 'predFilter', label: 'Pred', inInference: true },
  { key: 'classFilter', label: 'Class' }
];

export interface Filter {
  name?: string;
  comparator?: 'greater' | 'less' | 'equal' | 'between';
  greater_than?: number;
  less_than?: number;
  is_equal?: number;
  isin?: string[];
}

const metaFilterConverter = (metaFilters: MetaFilter[]) => {
  const filters = metaFilters.map((meta) => {
    let { name, greater_than, less_than, is_equal, isin } = meta;
    const comparator =
      greater_than && less_than
        ? COMPARATORS.BETWEEN
        : greater_than
          ? COMPARATORS.GREATER
          : less_than
            ? COMPARATORS.LESS
            : COMPARATORS.EQUAL;

    // We don't support 'isin' filters in the form so we need to convert it into something we can use
    if (name === 'target' && isin?.[0] === '') {
      name = 'target_length';
      is_equal = 0;
    } else if (isin) {
      return null;
    }

    return {
      name,
      comparator,
      greater_than,
      less_than,
      is_equal
    };
  });

  return filters.filter(Boolean) as Filter[];
};

const CreateSliceModal = () => {
  const [isAddingSlice, setIsAddingSlice] = useState(false);

  const { closeAll } = useModals();
  const shapeSelection = useEmbeddingsStore((state) => state.shapeSelection);
  const labels = useLabels();
  const createSlice = useCreateSlice();
  const metaFilters = useMetaFilters();

  const { currentProjectPermissions } = useProjectPermissions();
  const { edit_slice } = currentProjectPermissions;

  const [formMetaFilters, setFormMetaFilters] = useState<Filter[]>(
    metaFilterConverter(metaFilters)
  );
  const confidence = metaFilters.find((meta) => meta.name === 'confidence');

  const {
    searchTerm,
    depHigh,
    task,
    depLow,
    misclassifiedOnly,
    likelyMislabelled,
    correctlyClassified,
    isRegex,
    spanSearch,
    predFilter,
    goldFilter,
    classFilter,
    ...otherParams
  } = useParametersStore();
  const { setParameters, clearAndUpdateParameters, setMetaParameters } =
    useParametersStoreActions();

  const { isIc, isInference, isMltc, isNer, isOd, isSD, isSS, isS2S } =
    useComputedParameters();

  const accuracy = metaFilters.find((f) => f.name === 'accuracy');

  const accLow = accuracy?.greater_than || 0;
  const accHigh = accuracy?.less_than || 1;

  const activeLabelsFilters = LABEL_FILTERS.map(({ key }) => key).reduce(
    (labels, name) => {
      // @ts-expect-error FIXME:
      const values = splitFilters(otherParams?.[name])?.filter(Boolean);
      return {
        ...labels,
        [name]: values?.length ? values : null
      };
    },
    {}
  );

  const confLow = confidence?.greater_than || 0;
  const confHigh = confidence?.less_than || 1;

  const form = useForm({
    initialValues: {
      sliceName: '',
      searchTerm,
      misclassifiedOnly,
      correctlyClassified,
      likelyMislabelled,
      ...activeLabelsFilters,
      dep: [depLow * 100, depHigh * 100],
      accuracy: [accLow * 100, accHigh * 100],
      areaMin: 0,
      areaMax: null,
      isRegex,
      spanSearch,
      predFilter,
      goldFilter,
      classFilter,
      confidence: [confLow * 100, confHigh * 100],
      odErrors: useErrorTypesMetaFilter()?.isin
    }
  });

  const handleFilterApply = () => {
    const {
      dep,
      searchTerm,
      spanSearch,
      misclassifiedOnly,
      likelyMislabelled: lm,
      predFilter,
      goldFilter,
      classFilter,
      isRegex,
      confidence,
      odErrors,
      accuracy,
      areaMin,
      areaMax,
      correctlyClassified
    } = form.values;

    const [easy, hard] = dep?.map((percentage) => percentage / 100) || [0, 1];
    const [accMin, accMax] = accuracy?.map(
      (percentage) => percentage / 100
    ) || [0, 1];
    const [confLow, confHigh] = confidence?.map(
      (percentage) => percentage / 100
    ) || [0, 1];

    const filters = {
      searchTerm,
      depHigh: hard,
      depLow: easy,
      misclassifiedOnly,
      correctlyClassified,
      likelyMislabelled: lm,
      goldFilter: goldFilter,
      predFilter: predFilter,
      classFilter: classFilter,
      isRegex,
      spanSearch
    };

    const confidenceFilter =
      confHigh !== 1 || confLow !== 0
        ? [{ greater_than: confLow, less_than: confHigh, name: 'confidence' }]
        : [];

    const accuracyFilter =
      accMax !== 1 || accMin !== 0
        ? [{ greater_than: accMin, less_than: accMax, name: 'accuracy' }]
        : [];

    const areaFilter =
      areaMax || areaMin
        ? [{ greater_than: areaMin, less_than: areaMax, name: 'area' }]
        : [];

    const objectDetectionErrors = Array.isArray(odErrors)
      ? [{ name: 'galileo_error_type', isin: [...odErrors] }]
      : [];

    const nonEmptyFormMetaFilters = formMetaFilters.filter(
      (filter) =>
        !!filter.name &&
        (filter.greater_than || filter.less_than || filter.is_equal)
    );

    const mergedMetaFilters = metaFilters
      .filter((meta) => {
        // Remove existing meta filters if we are setting new ones
        const hasNewFilter = [
          ...accuracyFilter,
          ...areaFilter,
          ...objectDetectionErrors,
          ...confidenceFilter,
          ...nonEmptyFormMetaFilters
        ]
          .map((newMeta) => newMeta?.name)
          .includes(meta.name);

        return Boolean(!hasNewFilter);
      })
      .map((meta) => {
        // If filters were removed from the form we need to remove them from the meta filters store
        if (
          formMetaFilters.find(
            (f) =>
              f.name === meta.name ||
              (f.name === 'target_length' && meta.name === 'target')
          )
        ) {
          return meta;
        } else {
          return {
            name: meta.name,
            isin: []
          };
        }
      });

    const newMetaFilters = [
      ...mergedMetaFilters,
      ...confidenceFilter,
      ...accuracyFilter,
      ...areaFilter,
      ...objectDetectionErrors,
      ...nonEmptyFormMetaFilters
    ] as MetaFilter[];

    setMetaParameters(newMetaFilters);
    setParameters(filters);
    closeAll();
  };

  const handleCreateSlice = () => {
    // @ts-expect-error
    const values: FormValues = { ...form.values, metaFilters };
    if (values.sliceName) {
      createSlice.mutate(values, {
        onSuccess: (data) => {
          const { name, logic, id } = data || {};
          showNotification({
            title: `Slice ${name} created`,
            type: 'success'
          });

          clearAndUpdateParameters({
            correctlyClassified: !!logic?.correctly_classified,
            searchTerm: logic?.text_pat || '',
            isRegex: logic?.regex || false,
            depHigh: logic?.data_error_potential_high ?? 1,
            depLow: logic?.data_error_potential_low ?? 0,
            misclassifiedOnly: !!logic?.misclassified_only,
            likelyMislabelled: !!logic?.likely_mislabeled,
            goldFilter: logic?.gold_filter || [],
            predFilter: logic?.pred_filter || [],
            classFilter: logic?.class_filter || [],
            slice: id,
            spanSearch: logic?.span_text || '',
            // @ts-expect-error TODO: Types
            metaFilter: logic?.meta_filter || []
          });
          closeAll();
        }
      });
    }
  };
  return (
    <>
      <Box className='align-center' mb='xs'>
        {isMltc && !isAddingSlice && (
          <>
            <IconClipboardCheck color='gray' size={16} />
            <Text c='dimmed' ml={4} size='sm'>
              {task}
            </Text>
          </>
        )}
      </Box>
      <form>
        {isAddingSlice ? (
          <TextInput
            required
            label='Slice Name'
            {...form.getInputProps('sliceName')}
          />
        ) : (
          <>
            {!(isIc || isOd || isSD || isSS) && (
              <>
                <TextInput
                  label='Text Search'
                  mb={8}
                  rightSectionWidth={100}
                  {...form.getInputProps('searchTerm')}
                />
                <Switch
                  color='brand'
                  label='Regex search'
                  my={4}
                  {...form.getInputProps('isRegex', { type: 'checkbox' })}
                  defaultChecked={Boolean(isRegex)}
                />
              </>
            )}
            {isNer && (
              <TextInput
                label='Span Search'
                {...form.getInputProps('spanSearch')}
              />
            )}
            {!isS2S &&
              LABEL_FILTERS.filter(
                ({ inInference }) => !isInference || inInference
              )?.map(({ key, label }) =>
                labels.isLoading ? (
                  <Skeleton height={40} key={key} />
                ) : (
                  <MultiSelect
                    searchable
                    comboboxProps={{ withinPortal: false }}
                    data={labels?.data?.labels || []}
                    key={key}
                    label={`${label} Filters`}
                    maxDropdownHeight={160}
                    mb={2}
                    placeholder='No Filters Applied'
                    styles={{
                      input: { maxHeight: 100, overflow: 'auto' }
                    }}
                    {...form.getInputProps(key as FieldLabels)}
                  />
                )
              )}
            {(isOd || isSS) && (
              <MultiSelect
                searchable
                comboboxProps={{ withinPortal: false }}
                data={Object.entries(
                  isSS ? SS_ERROR_MAPPING : OD_ERROR_MAP
                ).map((err) => ({
                  label: err[1].label,
                  value: err[0]
                }))}
                label='Error Types'
                maxDropdownHeight={160}
                placeholder='No Filters Applied'
                styles={{
                  input: { maxHeight: 100, overflow: 'auto' }
                }}
                {...form.getInputProps('odErrors')}
              />
            )}
            {isInference ? (
              <Box mb='xs'>
                <Text mb={4} mt='sm' size='sm'>
                  Confidence
                </Text>
                <RangeSlider
                  label={(value) => value / 100}
                  marks={[
                    { value: 25, label: 0.25 },
                    { value: 50, label: 0.5 },
                    { value: 75, label: 0.75 }
                  ]}
                  minRange={0.01}
                  style={{ width: '100%' }}
                  {...form.getInputProps('confidence')}
                />
              </Box>
            ) : (
              <>
                <Box
                  mb='xs'
                  mt='xs'
                  p={8}
                  pb={24}
                  style={{
                    border: '1px solid #E0E0E0',
                    borderRadius: 8
                  }}
                >
                  <Text mb={4} size='sm'>
                    {isSS && 'Polygon '}DEP Score
                  </Text>
                  <RangeSlider
                    label={(value) => value / 100}
                    marks={[
                      { value: 25, label: 0.25 },
                      { value: 50, label: 0.5 },
                      { value: 75, label: 0.75 }
                    ]}
                    style={{ width: '100%' }}
                    {...form.getInputProps('dep')}
                  />
                </Box>
                {!(isOd || isSS || isS2S) && (
                  <>
                    <Switch
                      color='brand'
                      label='Misclassified Only'
                      mt='xs'
                      {...form.getInputProps('misclassifiedOnly', {
                        type: 'checkbox'
                      })}
                      defaultChecked={Boolean(misclassifiedOnly)}
                    />
                    <Switch
                      color='brand'
                      label='Correctly Classified Only'
                      mt='xs'
                      {...form.getInputProps('correctlyClassified', {
                        type: 'checkbox'
                      })}
                      defaultChecked={Boolean(correctlyClassified)}
                    />
                  </>
                )}
                {!(isMltc || isOd || isSS || isS2S) && (
                  <Switch
                    color='brand'
                    label='Likely Mislabeled'
                    mt='xs'
                    {...form.getInputProps('likelyMislabelled', {
                      type: 'checkbox'
                    })}
                    defaultChecked={Boolean(likelyMislabelled)}
                  />
                )}
              </>
            )}
            {isSS && (
              <>
                <Box mb='lg'>
                  <Text mb={4} mt='sm' size='sm'>
                    Polygon pixel accuracy
                  </Text>
                  <RangeSlider
                    label={(value) => value / 100}
                    marks={[
                      { value: 25, label: 0.25 },
                      { value: 50, label: 0.5 },
                      { value: 75, label: 0.75 }
                    ]}
                    style={{ width: '100%' }}
                    {...form.getInputProps('accuracy')}
                  />
                  <Group mt='md'>
                    <NumberInput
                      label='Polygon min size (px)'
                      {...form.getInputProps('areaMin')}
                    />
                    <NumberInput
                      label='Polygon max size (px)'
                      {...form.getInputProps('areaMax')}
                    />
                  </Group>
                </Box>
              </>
            )}

            <MetaFiltersForm
              metaFilters={formMetaFilters}
              setMetaFilters={setFormMetaFilters}
            />
            {Boolean(metaFilters?.length) &&
              metaFilters
                ?.filter(
                  ({ name, isin }) =>
                    name !== 'confidence' &&
                    isin?.length &&
                    !(isin?.length === 1 && !isNaN(isin[0] as number))
                )
                .map(({ isin, name }) => {
                  const tooltipLabel = isin?.join(', ');
                  const badgeText = truncateArray(isin ?? []);
                  const copy =
                    name === 'galileo_error_type'
                      ? badgeText
                      : `${name}: ${badgeText}`;
                  return (
                    <Tooltip withArrow key={name} label={tooltipLabel}>
                      <Badge copy={copy} />
                    </Tooltip>
                  );
                })}
            <Box className='justify-space-between' mt='xl'>
              {edit_slice.allowed && (
                <Button
                  copy='Apply &amp; Create Slice'
                  id='apply-and-close-filter-button'
                  isLoading={createSlice?.isLoading}
                  variant='light'
                  onClick={() => setIsAddingSlice(true)}
                />
              )}
              <Button
                copy='Apply &amp; Close'
                id='apply-and-create-slice-button'
                isLoading={createSlice?.isLoading}
                onClick={handleFilterApply}
              />
            </Box>
          </>
        )}
        {isAddingSlice && (
          <Group justify='flex-end'>
            <Tooltip
              multiline
              withArrow
              disabled={!shapeSelection}
              label='Slices cannot be created with selected points. Please remove selection to continue.'
              position='bottom-start'
              w={250}
            >
              {edit_slice.allowed && (
                <Button
                  copy='Create Slice'
                  disabled={Boolean(shapeSelection || !form.values.sliceName)}
                  isLoading={createSlice?.isLoading}
                  mt='xl'
                  onClick={handleCreateSlice}
                />
              )}
            </Tooltip>
          </Group>
        )}
      </form>
    </>
  );
};

export default CreateSliceModal;
