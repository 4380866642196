import { ActionIcon, Box, Text, Tooltip } from '@mantine/core';
import {
  IconArrowDown,
  IconArrowUp,
  IconFilter,
  IconMinus
} from '@tabler/icons-react';
import { cx } from 'classix';

import { ASC, DESC } from '@/core/constants/query-params.constants';
import {
  useParametersStore,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';

import ColumnFilter from '../column-filter/column-filter';
import ErrorTypeFilter from '../error-type-filter/error-type-filter';

interface TableHeaderProps {
  column: {
    accessor: string;
    align?: 'left' | 'center' | 'right';
    color?: string;
    extraHeaderContent?: () => React.ReactNode;
    isFilterable?: boolean;
    isMeta?: boolean;
    isSortable?: boolean;
    label: string | React.ReactNode;
    objectAccessor?: string;
    uniqueValues?: string[];
    uniqueValuesCount?: number;
    uniqueValuesFull?: string[];
    width?: number | string;
  };
}

const GLOSSARY: Record<string, string> = {
  data_error_potential:
    'An instance-level score measuring the misfit of the sample to the model. Higher score indicates error is more probable, where error could be one or more reasons - boundary samples, mislabelled classes, misclassified classes, noisy/corrupt samples, out-of-distribution etc.',
  total_errors:
    'Sum of all errors in a given sample i.e. span shift errors, wrong tag errors, missed span errors and ghost span errors',
  span_shift: 'Errors from misaligned but overlapped predicted and gold spans',
  wrong_tag:
    'Errors from aligned predicted and gold spans, but with mismatched labels',
  missed_label: 'Errors from gold spans with no predicted spans',
  ghost_span: 'Errors from prediction spans with no gold spans',
  drift_score:
    'A higher OOC / Drift score indicates that a sample is less represented in the training data - i.e. more out of distribution. Specifically, an OOC / Drift score of 0.96 means that a sample is in the 96th percentile of training embedding dissimilarity. For more technical details check out our documentation!'
};

/** Used when sorting columns by a nested field */
const SORT_BY_DELIMITER = '//';

const TableHeader = ({ column }: TableHeaderProps) => {
  const sortBy = useParametersStore((state) => state.sortBy);
  const sortDirection = useParametersStore((state) => state.sortDirection);
  const { setParameters } = useParametersStoreActions();
  let {
    accessor,
    align,
    color,
    isFilterable,
    isSortable,
    label,
    objectAccessor,
    uniqueValues,
    uniqueValuesCount,
    uniqueValuesFull
  } = column || {};

  align = align ?? 'center';

  // Check for nested sort by parameter
  const isSorted =
    objectAccessor != null
      ? sortBy === [objectAccessor, accessor].join(SORT_BY_DELIMITER)
      : sortBy === accessor;

  const justify =
    align === 'center'
      ? 'justify-center'
      : align === 'left'
        ? 'justify-flex-start'
        : 'justify-flex-end';

  const handleHeaderClick = () => {
    if (isSortable) {
      const newSortDirection = sortDirection === ASC ? DESC : ASC;

      // Set parameter for nested field if object accessor is defined
      const newSortBy =
        objectAccessor != null
          ? [objectAccessor, accessor].join(SORT_BY_DELIMITER)
          : accessor;

      setParameters({ sortDirection: newSortDirection, sortBy: newSortBy });
    }
  };

  const getIcon = () => {
    if (!sortBy && !sortDirection && accessor === 'data_error_potential') {
      return (
        <IconArrowDown
          color='#706C89'
          data-testid='chevron-down-icon'
          size={16}
        />
      );
    }

    if (isSorted) {
      return sortDirection === ASC ? (
        <IconArrowUp
          aria-label='sorted ascending'
          color='#706C89'
          data-testid='chevron-up-icon'
          size={16}
        />
      ) : (
        <IconArrowDown
          aria-label='sorted descending'
          color='#706C89'
          data-testid='chevron-down-icon'
          size={16}
        />
      );
    } else {
      return (
        <IconMinus
          aria-label='unsorted'
          color='#706C89'
          data-testid='minus-icon'
          size={16}
        />
      );
    }
  };

  const hasTooManyUniqueValues =
    (uniqueValuesCount || 0) > 0 && !uniqueValues?.length;

  const disableTooltip = !GLOSSARY[accessor] && !hasTooManyUniqueValues;

  return (
    <Tooltip
      multiline
      withArrow
      withinPortal
      disabled={disableTooltip}
      label={
        hasTooManyUniqueValues
          ? `Too many unique values for column ${accessor}. Select a subset of data to enable`
          : GLOSSARY[accessor]
      }
      w={250}
    >
      <Box
        className={cx(justify, 'align-items-baseline cursor-pointer')}
        data-testid='table-header'
        style={{
          width: column?.width ?? '100%'
        }}
        onClick={handleHeaderClick}
      >
        {color && (
          <Box
            mr={4}
            style={{
              height: 10,
              width: 10,
              borderRadius: 8,
              background: color
            }}
          />
        )}
        {isSortable && getIcon()}
        {accessor === 'error_types' && <ErrorTypeFilter />}
        {!isSortable &&
          isFilterable &&
          (hasTooManyUniqueValues ? (
            <ActionIcon disabled mr={4}>
              <IconFilter color='#706C89' size={16} />
            </ActionIcon>
          ) : (
            <ColumnFilter
              columnId={accessor}
              uniqueValues={
                (uniqueValuesCount || 0) < 500 ? uniqueValuesFull : uniqueValues
              }
            />
          ))}
        <Text
          c='gray.6'
          className='truncate'
          fw={600}
          px='xs'
          size='sm'
          ta={align}
        >
          {label}
        </Text>
        {column?.extraHeaderContent ? column?.extraHeaderContent() : null}
      </Box>
    </Tooltip>
  );
};

export default TableHeader;
