import {
  Box,
  Group,
  Text,
  Tooltip,
  TooltipProps,
  useMantineTheme
} from '@mantine/core';

interface MetricTemplateLabelProps {
  templateVersionSelected: boolean;
  templateName: string;
  tooltipProps?: Partial<TooltipProps>;
}

const MetricTemplateLabel = ({
  templateVersionSelected,
  templateName,
  tooltipProps
}: MetricTemplateLabelProps) => {
  const { colors } = useMantineTheme();

  const backgroundColor = templateVersionSelected
    ? colors.brand[1]
    : colors.contrast[8];

  return (
    <Box
      bg={backgroundColor}
      c={colors.contrast[4]}
      data-testid='metric-template-label'
      px='0.5rem'
      py='0.25rem'
      style={{
        display: 'inline-block',
        borderRadius: '0.5rem'
      }}
    >
      <Group gap='0.75rem' wrap='nowrap'>
        {templateVersionSelected && (
          <Box
            bg={colors.brand[5]}
            c='white'
            fw='800'
            fz='0.75rem'
            ml='-0.25rem'
            px='0.375rem'
            py='0.2rem'
            style={{
              borderRadius: '0.375rem'
            }}
          >
            In Prod
          </Box>
        )}
        <Tooltip label={templateName} {...tooltipProps}>
          <Text
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {templateName}
          </Text>
        </Tooltip>
      </Group>
    </Box>
  );
};

export default MetricTemplateLabel;
