import { ColorSchemeScript } from '@mantine/core';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ReactElement, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import '@mantine/core/styles.css';
import '@mantine/code-highlight/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@/core/styles/globals.css';
import '@/core/styles/tables.css';
import '@/core/styles/modals.css';
import '@/fine-tune/styles/computer-vision.css';
import '@/core/styles/selects.css';
import '@/evaluate/styles/prompt-eval.css';

import DefaultErrorBoundary from '@/core/components/atoms/default-error-boundary/default-error-boundary';
import AuthAndFlagsProvider from '@/core/providers/auth-provider/auth-provider';
import { GleapProvider } from '@/core/providers/gleap-provider/gleap-provider';
import MantineAndReactQueryProvider from '@/core/providers/mantine-and-react-query-provider/mantine-and-react-query-provider';
import PostHogProvider from '@/core/providers/posthog-provider/posthog-provider';
import { FeatureFlagResponse } from '@/core/stores/feature-flags-store/feature-flags.store.types';

type CustomAppProps = AppProps & {
  Component: AppProps['Component'] & {
    auth: boolean;
    getLayout?: (page: ReactElement) => ReactNode;
  };
  pageProps: { session: Session; featureFlags?: FeatureFlagResponse };
};

const App = ({
  Component,
  pageProps: { session, featureFlags, ...pageProps }
}: CustomAppProps) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
      <Head>
        <ColorSchemeScript forceColorScheme='light' />
        <meta
          content='minimum-scale=1, initial-scale=1, width=device-width'
          name='viewport'
        />
        <link
          href='/favicon-32x32.png'
          rel='icon'
          sizes='32x32'
          type='image/png'
        />
        <link
          href='/favicon-16x16.png'
          rel='icon'
          sizes='16x16'
          type='image/png'
        />
        <link
          href='/apple-touch-icon.png'
          rel='apple-touch-icon'
          sizes='180x180'
        />
        <link href='/site.webmanifest' rel='manifest' />
        <link color='#5bbad5' href='/safari-pinned-tab.svg' rel='mask-icon' />
        <title>Galileo - Next Generation of Data Tools for ML</title>
      </Head>

      {/* NextAuth Provider */}
      <PostHogProvider>
        <SessionProvider session={session}>
          <MantineAndReactQueryProvider
            pageProps={pageProps}
            withAuth={Boolean(Component.auth)}
          >
            <AuthAndFlagsProvider
              featureFlags={featureFlags}
              withAuth={Boolean(Component.auth)}
            >
              <GleapProvider>
                {getLayout(<Component {...pageProps} />)}
              </GleapProvider>
            </AuthAndFlagsProvider>
          </MantineAndReactQueryProvider>
        </SessionProvider>
      </PostHogProvider>
    </ErrorBoundary>
  );
};

export default App;
