import { SelectProps } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import {
  IconArrowsShuffle,
  IconDownload,
  IconEdit,
  IconPencilPlus,
  IconPlus,
  IconShoppingCartPlus,
  IconSwitch2,
  IconTag,
  IconTrash,
  IconWaveSine
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { useIntegration } from '@/core/hooks/query-hooks/use-integration/use-integration';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { useProjectPermissions } from '@/core/hooks/use-permissions/use-project-permissions';
import { useAreEmbeddingsEnabled } from '@/fine-tune/hooks/query-hooks/use-are-embeddings-enabled/use-are-embeddings-enabled';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import { useInferenceNames } from '@/fine-tune/hooks/query-hooks/use-inference-names/use-inference-names';
import { useSimilarFromOtherSplit } from '@/fine-tune/hooks/query-hooks/use-similar-from-other-split/use-similar-from-other-split';
import { useSimilarToIds } from '@/fine-tune/hooks/query-hooks/use-similar-to-ids/use-similar-to-ids';
import { useSplits } from '@/fine-tune/hooks/query-hooks/use-splits/use-splits';
import { useSelectedSamplesAmount } from '@/fine-tune/hooks/use-selected-samples-amount/use-selected-samples-amount';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import {
  useComputedParameters,
  useParametersStore,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';
import { Splits } from '@/fine-tune/stores/parameters-store/parameters.store.types';
import useStore from '@/fine-tune/stores/store';

// Exporting for testing only
export const SIZE = 16;
const MAX_SIMILAR_TO_SAMPLES = 5000;

const isGreaterThanMax = (value: number) => value > MAX_SIMILAR_TO_SAMPLES;

export const ACTION_IDS = {
  SIMILAR_TO_ACTION_ID: 'similar-to',
  EXPORT_ACTION_ID: 'export',
  ADD_TO_LABELS_CART_ACTION_ID: 'add-to-labels-cart',
  SET_LABELS_ACTION_ID: 'set-label',
  REMOVE_ACTION_ID: 'remove',
  CHANGE_LABEL_ACTION_ID: 'change-label',
  EDIT_DATA_ACTION_ID: 'edit-data',
  SEND_FOR_RELABEL_ACTION_ID: 'send',
  REPLACE_WITH_PREDICTION_ACTION_ID: 'replace-with-prediction',
  REPLACE_WITH_GOLD_ACTION_ID: 'replace-with-gold'
};

export interface IButtonsData {
  id: string;
  label: string;
  tooltipText?: string;
  icon: React.ReactNode;
  onClick?: (id?: number) => void;
  disabled?: boolean;
  isVisible: boolean;
  rightIcon?: React.ReactNode;
  selectOptions?: SelectProps['data'];
  handleSelectChange?: (split: Splits) => void;
  selectValue?: string | undefined;
}
/**
 * useActionButtons
 *
 */
export const useActionButtons = () => {
  const { data } = useIntegration('labelstudio');
  const isLabelstudioActive = Boolean(data?.name);

  const { data: enabledData } = useAreEmbeddingsEnabled();
  const hasEmbs = Boolean(
    enabledData?.has_data_embs || enabledData?.has_model_embs
  );

  const { clearAndUpdateParameters, clearParameters } =
    useParametersStoreActions();
  const { isIc, isInference, isOd, isSD, isS2S, isNer } =
    useComputedParameters();
  const inferenceName = useParametersStore((s) => s.inferenceName);
  const metric = useParametersStore((s) => s.metric);
  const split = useParametersStore((s) => s.split);

  const [similarToSplit, setSimilarToSplit] = useState(split as string);

  const allRowsSelected = useStore((s) => s.allRowsSelected);
  const selectedRows = useStore((s) => s.selectedRows);
  const selectedSpans = useStore((s) => s.selectedSpans);
  const selectedObjects = useStore((s) => s.selectedObjects);

  const setShapeSelection = useEmbeddingsStore(
    (s) => s.actions.setShapeSelection
  );

  const {
    setSimilarToIds,
    setSimilarToQuantity,
    setSimilarFromIds,
    clearSelectionState
  } = useStore((s) => s.actions);

  const { getSelectedCount } = useSelectedSamplesAmount();
  const selectedSamples = getSelectedCount() as number;
  const inferenceNames = useInferenceNames();
  const _splits = useSplits();

  const splitsWithData = _splits?.data?.splits || [];

  const splits = ['Training', 'Validation', 'Test'].filter((label: string) =>
    splitsWithData.includes(label.toLowerCase() as Splits)
  );
  const splitsOptions = {
    group: 'Splits',
    items: splits.map((label: string) => ({
      label,
      value: label.toLowerCase()
    }))
  };

  const inferenceOptions = {
    group: 'Inference',
    items:
      inferenceNames?.data?.inference_names?.map((name: string) => ({
        value: name,
        label: name
      })) || []
  };

  const similarToOptions: SelectProps['data'] = [
    splitsOptions,
    inferenceOptions
  ];

  const { openModal } = useModals();

  const similarIds = useSimilarToIds();
  const { samplesIds } = useEditCreate();

  const similarFromOtherSplit = useSimilarFromOtherSplit();

  const { currentProjectPermissions } = useProjectPermissions();
  const { export_data, update } = currentProjectPermissions;

  const updateSimilarFrom = (
    similarToIds: number[],
    isInferenceName: boolean
  ) => {
    similarFromOtherSplit.mutate(
      { fromSplit: similarToSplit, similarToIds, isInferenceName },
      {
        onSuccess: (data) => {
          const fromSplit =
            data?.split === 'inference' ? data.inference_name : data?.split;
          setSimilarToIds(similarToIds);
          setSimilarFromIds(data?.similar_ids || []);
          clearAndUpdateParameters({
            split: isInferenceName ? 'inference' : (data?.from_split as Splits),
            groupedBy: isInferenceName ? 'pred' : 'gold',
            metric: isInferenceName ? 'confidence' : metric,
            fromSplit: fromSplit || '',
            inferenceName: isInferenceName ? similarToSplit : '',
            sortBy: isInferenceName ? 'confidence' : 'data_error_potential'
          });
        }
      }
    );
  };

  const updateSimilarToParam = (rowId?: number) => {
    setShapeSelection(null);
    clearSelectionState();
    setSimilarToQuantity(undefined);

    let similarToIds =
      typeof rowId === 'number'
        ? [rowId]
        : allRowsSelected
          ? similarIds.data?.ids || []
          : samplesIds;

    if (isOd) {
      similarToIds = selectedObjects;
    }

    const isInferenceName = Boolean(
      inferenceNames?.data?.inference_names.includes(similarToSplit)
    );

    const isSearchingWithinSplit = isInferenceName
      ? similarToSplit === inferenceName
      : similarToSplit === split;

    if (isSearchingWithinSplit) {
      setSimilarToIds(similarToIds);
      clearParameters([
        'inferenceName',
        'split',
        'task',
        'groupedBy',
        'sortBy'
      ]);
    } else {
      updateSimilarFrom(similarToIds, isInferenceName);
    }

    closeAllModals();
  };

  useEffect(() => {
    const newSplit = isInference ? inferenceName : split;
    setSimilarToSplit(newSplit as string);
  }, [split, inferenceName, isInference]);

  const validSelectedRows = selectedRows?.length || selectedSpans?.length;

  const isRemoveOrChangeActive = !validSelectedRows && !allRowsSelected;

  const disableExport =
    (!selectedRows?.length && !allRowsSelected) || !export_data.allowed;

  const disableSimilarTo = allRowsSelected
    ? isGreaterThanMax(selectedSamples)
    : validSelectedRows === 0 || isGreaterThanMax(validSelectedRows);

  const isSelectingPredSpans =
    isNer && selectedSpans.filter((span) => Boolean(span.pred)).length > 0;

  const buttons: IButtonsData[] = [
    {
      id: ACTION_IDS.SIMILAR_TO_ACTION_ID,
      label: `Find similar from`,
      icon: <IconWaveSine size={SIZE} />,
      onClick: (rowId?: number) => updateSimilarToParam(rowId),
      disabled: disableSimilarTo,
      tooltipText: 'Similar to requires less than 5K samples to be selected',
      selectOptions: similarToOptions,
      handleSelectChange: (split: Splits) => {
        setSimilarToSplit(split);
      },
      selectValue: similarToSplit,
      isVisible: !isSD && hasEmbs
    },
    {
      id: ACTION_IDS.EXPORT_ACTION_ID,
      label: 'Export',
      onClick: (rowId?: number) => openModal(Modals.EXPORTS, { rowId }),
      icon: <IconDownload size={SIZE} />,
      disabled: disableExport,
      tooltipText: 'Select rows to export',
      isVisible: true
    },

    {
      id: ACTION_IDS.ADD_TO_LABELS_CART_ACTION_ID,
      label: 'Add to Labels Cart',
      icon: <IconShoppingCartPlus size={SIZE} />,
      disabled: Boolean(isRemoveOrChangeActive),
      isVisible: Boolean(isInference && !isOd && update.allowed),
      onClick: (rowId?: number) => openModal(Modals.REMOVE_OR_SELECT, { rowId })
    },
    {
      id: ACTION_IDS.SET_LABELS_ACTION_ID,
      label: 'Set Label',
      icon: <IconPencilPlus size={SIZE} />,
      disabled: Boolean(isRemoveOrChangeActive),
      isVisible: Boolean(isInference && !isOd && update.allowed),
      onClick: (rowId?: number) => openModal(Modals.SET_LABEL, { rowId })
    },
    {
      id: ACTION_IDS.REMOVE_ACTION_ID,
      label: 'Remove',
      icon: <IconTrash size={SIZE} />,
      disabled: Boolean(isRemoveOrChangeActive || isSelectingPredSpans),
      isVisible: !isInference && !isOd && update.allowed,
      tooltipText: isSelectingPredSpans
        ? 'Cannot perform this action when a prediction span is selected'
        : undefined,
      onClick: (rowId?: number) => openModal(Modals.REMOVE_OR_SELECT, { rowId })
    },
    {
      id: ACTION_IDS.CHANGE_LABEL_ACTION_ID,
      label: 'Change Label',
      icon: <IconSwitch2 size={SIZE} />,
      disabled: Boolean(isRemoveOrChangeActive || isSelectingPredSpans),
      isVisible: !isInference && !isOd && !isS2S && update.allowed,
      tooltipText: isSelectingPredSpans
        ? 'Cannot perform this action when a prediction span is selected'
        : undefined,
      onClick: (rowId?: number) => openModal(Modals.SET_LABEL, { rowId })
    },
    {
      id: ACTION_IDS.REPLACE_WITH_PREDICTION_ACTION_ID,
      label: 'Overwrite Ground Truth',
      icon: <IconEdit size={SIZE} />,
      disabled: Boolean(isRemoveOrChangeActive),
      isVisible: isOd && update.allowed,
      onClick: (rowId?: number) =>
        openModal(Modals.REPLACE_WITH_PREDICTED, { rowId })
    },
    {
      id: ACTION_IDS.EDIT_DATA_ACTION_ID,
      label: 'Edit Data',
      icon: <IconEdit size={SIZE} />,
      disabled: Boolean(isRemoveOrChangeActive),
      isVisible:
        !isOd && !isSD && !isInference && !isIc && !isS2S && update.allowed,
      onClick: (rowId?: number) => {
        openModal(Modals.DATA_EDITS_CART, { rowId });
      }
    },
    {
      id: ACTION_IDS.SEND_FOR_RELABEL_ACTION_ID,
      label: isOd ? 'Send to Annotators' : 'Send for Relabel',
      icon: <IconTag size={SIZE} />,
      disabled: !isLabelstudioActive || isRemoveOrChangeActive,
      isVisible: !isSD && !isInference && update.allowed,
      tooltipText:
        'Sends samples directly to your labelers. To enable this you will need an integration with your labeling tool',
      onClick: () => openModal(Modals.SEND_TO_LABELER)
    }
  ];

  const objectDetectionModalButtons = (editId: number) => {
    return [
      {
        id: ACTION_IDS.SIMILAR_TO_ACTION_ID,
        label: `Find similar to`,
        icon: <IconWaveSine size={SIZE} />,
        onClick: updateSimilarToParam,
        selectOptions: similarToOptions,
        handleSelectChange: (split: Splits) => {
          setSimilarToSplit(split);
        },
        selectValue: similarToSplit,
        isVisible: true
      },
      {
        id: ACTION_IDS.SET_LABELS_ACTION_ID,
        label: 'Re-Label Ground Truth',
        icon: <IconTag size={SIZE} />,
        isVisible: update.allowed,
        onClick: () => openModal(Modals.SET_LABEL, { rowId: editId })
      },
      {
        id: ACTION_IDS.REMOVE_ACTION_ID,
        label: 'Remove Ground Truth',
        icon: <IconTrash size={SIZE} />,
        isVisible: update.allowed,
        onClick: () => openModal(Modals.REMOVE_OR_SELECT, { rowId: editId })
      },

      {
        id: ACTION_IDS.REPLACE_WITH_GOLD_ACTION_ID,
        label: 'Overwrite Ground Truth',
        icon: <IconArrowsShuffle size={SIZE} />,
        isVisible: update.allowed,
        onClick: () => openModal(Modals.RELABEL, { rowId: editId })
      },
      {
        id: ACTION_IDS.REPLACE_WITH_GOLD_ACTION_ID,
        label: 'Add to Ground Truth',
        icon: <IconPlus size={SIZE} />,
        isVisible: update.allowed,
        onClick: () => openModal(Modals.REPLACE_WITH_GOLD, { rowId: editId })
      }
    ];
  };

  return {
    updateSimilarToParam,
    buttons,
    objectDetectionModalButtons
  };
};
