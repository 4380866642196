import { useSessionStorage } from '@mantine/hooks';

import { FlagTags } from '@/core/types/feature-flags.types';

export const useABFlags = () => {
  const [observeHumanRatingsFlag, setObserveHumanRatingsFlag] =
    useSessionStorage({
      key: 'observe_human_ratings',
      defaultValue: false
    });

  return {
    observeHumanRatings: {
      description: 'Toggle Human Ratings for Observe.',
      isEnabled: observeHumanRatingsFlag,
      tag: [FlagTags.Feature],
      title: 'Human Ratings for Observe',
      toggle: () => setObserveHumanRatingsFlag(!observeHumanRatingsFlag)
    }
  };
};
