import { MantineFontSize, Text } from '@mantine/core';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';

import Metric, { MetricColor, MetricProps } from '../metric/metric';
import MetricExplanationBody from '../../molecules/metric-explanation-body/metric-explanation-body';
import { MetricPopover } from '../../molecules/metric-popover/metric-popover';
import { BaseSuccessMetricStatusProps } from '../../molecules/metric-status/metric-status';

export const getMetricProps = (
  value: string | undefined
): { color: MetricColor; variant: MetricProps['variant'] } => {
  switch (value) {
    case 'TIMED_OUT':
      return { color: 'yellow', variant: 'filled' };
    case 'ERROR':
      return { color: 'red', variant: 'outline' };
    case 'TRIGGERED':
      return { color: 'red', variant: 'filled' };
    default:
      return { color: 'gray', variant: 'filled' };
  }
};

type ProtectStatusMetricProps = BaseSuccessMetricStatusProps<{
  size?: MantineFontSize;
}>;

const ProtectStatusMetric = ({
  explanation,
  popoverProps,
  rationale,
  value,
  size = 'sm',
  ...metricProps
}: ProtectStatusMetricProps) => {
  const valueAsString: string | undefined =
    value != null && typeof value === 'string' ? value : undefined;

  const { color, variant } = getMetricProps(valueAsString);

  const contentElement = (
    <Text fw={700} size={size} ta='center'>
      {_startCase(_camelCase(valueAsString))}
    </Text>
  );

  return (
    <MetricPopover
      body={
        <MetricExplanationBody
          cost={undefined}
          explanation={explanation}
          rationale={rationale}
        />
      }
      disabled={explanation == null && rationale == null}
      {...popoverProps}
    >
      <Metric
        color={color}
        data-testid='protect-status-metric'
        value={contentElement}
        {...metricProps}
        variant={variant}
      />
    </MetricPopover>
  );
};

export default ProtectStatusMetric;
